import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { HelpiiUser } from '@models/client.model';
import { DossierWithClient } from '@models/dossier.model';
import { HistoryEvent } from '@models/history-event.model';
import { Order } from '@models/orders';
import { Options } from '@wizbii-utils/angular/webservices';
import { Client, LocaleEnum } from '@wizbii/utils/models';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HelpiiWebservice {
  private readonly _http = inject(HttpClient);
  private readonly _apiUrl = environment.api.fibii;

  getClientByUserId(userId: string, options?: Options): Observable<Client> {
    return this._http.get<Client>(`${this._apiUrl}/v2/clients/${userId}`, options);
  }

  getAdvisors(options?: Options): Observable<HelpiiUser[]> {
    return this._http.get<HelpiiUser[]>(`${this._apiUrl}/users`, options);
  }

  assignClients(
    fromOwnerId: string | null,
    toOwnerIds: string[],
    limit: number | null,
    onlyAccompaniment: boolean = false,
    country: string,
    options?: Options
  ): Observable<Client[]> {
    return this._http.post<Client[]>(
      `${this._apiUrl}/v2/clients/assign`,
      {
        fromOwnerId,
        toOwnerIds,
        limit,
        onlyAccompaniment,
        country,
      },
      options
    );
  }

  getClientCountByOwnerId(options?: Options): Observable<number> {
    return this._http.get<number>(`${this._apiUrl}/v2/clients/owner/count`, options);
  }

  getHistoryEvents(options?: Options): Observable<HistoryEvent[]> {
    return this._http.get<HistoryEvent[]>(`${this._apiUrl}/history-events`, options);
  }

  createHistoryEvent(historyEvent: Partial<HistoryEvent>, options?: Options): Observable<HistoryEvent> {
    return this._http.post<HistoryEvent>(`${this._apiUrl}/history-events`, historyEvent, options);
  }

  updateHistoryEvent(historyEvent: Partial<HistoryEvent>, options?: Options): Observable<HistoryEvent> {
    return this._http.put<HistoryEvent>(`${this._apiUrl}/history-events`, historyEvent, options);
  }

  deleteHistoryEvent(historyEventId: string, options?: Options): Observable<HistoryEvent> {
    return this._http.delete<HistoryEvent>(`${this._apiUrl}/history-events/${historyEventId}`, options);
  }

  trackPrivateUserFileAccess(documentId: string, options?: Options): Observable<any> {
    return this._http.post(
      `${this._apiUrl}/access-log/access_private_user_file`,
      {
        metaData: { documentId },
      },
      options
    );
  }

  getDossiersFromIds(dossierIds: string[], options?: Options): Observable<DossierWithClient[]> {
    const fetchDossierChunk = (ids: string[], options?: Options) =>
      this._http.get<DossierWithClient[]>(`${this._apiUrl}/v2/dossiers/ids`, {
        ...options,
        params: {
          ...options?.params,
          dossierIds: ids.join(','),
        },
      });
    const chunkedDossiers = dossierIds.reduce((resultArray: string[][], item, index) => {
      const chunkSize = 100;
      const chunkIndex = Math.floor(index / chunkSize);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex] = [...resultArray[chunkIndex], item];

      return resultArray;
    }, []);
    return forkJoin(chunkedDossiers.map((ids) => fetchDossierChunk(ids, options).pipe(catchError(() => [])))).pipe(
      map((chunkedDossiers) => chunkedDossiers.reduce((acc, chunk) => [...acc, ...chunk], []))
    );
  }

  getOrdersFromIds(ordersIds: string[], options?: Options): Observable<Order[]> {
    if (ordersIds.length === 0) return of([]);
    const fetchDossierChunk = (ids: string[], options?: Options) =>
      this._http.get<Order[]>(`${this._apiUrl}/orders/by-ids`, {
        ...options,
        params: { ...options?.params, ids: ids.join(',') },
      });
    const chunkedOrders = ordersIds.reduce((resultArray: string[][], item, index) => {
      const chunkSize = 100;
      const chunkIndex = Math.floor(index / chunkSize);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex] = [...resultArray[chunkIndex], item];

      return resultArray;
    }, []);
    return forkJoin(chunkedOrders.map((ids) => fetchDossierChunk(ids, options).pipe(catchError(() => [])))).pipe(
      map((orders) => orders.reduce((acc, chunk) => [...acc, ...chunk], []))
    );
  }

  validateDropitoData(
    suggestionId: string,
    userId: string,
    locale = LocaleEnum.fr_FR,
    options?: Options
  ): Observable<any> {
    return this._http.get(`${environment.api.fibii}/dropito/used-data/${locale}/${suggestionId}/${userId}`, options);
  }

  createDropitoTask(userId: string, helpId: string, locale = LocaleEnum.fr_FR, options?: Options): Observable<any> {
    return this._http.post(
      `${environment.api.fibii}/dropito/task/${locale}`,
      {
        userId,
        helpId,
      },
      options
    );
  }

  generatedAndDownloadRIB(userId: string, options?: Options): Observable<Blob> {
    return this._http.get(`${environment.api.bankingProcessor}/v1/user/${userId}/bankAccount/rib`, {
      ...options,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      responseType: 'blob',
    });
  }
}
